.form-success {
  text-align: center;
  font-size: 24px;
  margin-top: 5px;
  color: #fff;
}

.form-content-right {
  border-radius: 0 10px 10px 0;
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 100%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 100%;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
  background-color: #e6e6e6;
}

.form-input-message {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 80px;
  width: 100%;
  border: none;
  background-color: #e6e6e6;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input-btn {
  width: 100px;
  padding: 10px;
  margin-top: 2rem;
  background-color: #fff;
  outline: none;
  border: 1px solid #000;
  font-size: 1rem;
}

.form-input-btn:hover {
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgb(230 230 230) 0%,
    rgb(217 219 220) 100%
  );
  transition: all 0.4s ease-out;
}

@media screen and (max-width: 600px) {
  .form-content-right {
    width: 100%;
    margin-top: 15%;
  }
}

.form-container.light-theme {
  background-color: #fff;
  color: #000;
}

.form-content-right.light-theme h1 {
  background-color: #fff;
  color: #000;
}

.form.light-theme {
  background-color: #fff;
  color: #000;
}

.form-inputs.light-theme {
  background-color: #fff;
  color: #000;
}

.form-input.light-theme {
  background-color: #e6e6e6;
  color: #000;
}

.form-input-btn.light-theme {
  background-color: #fff;
  color: #000;
}

.form-label.light-theme {
  background-color: #fff;
  color: #000;
}
