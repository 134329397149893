.gal-container {
  text-align: center;
  /* margin: 50px 0;*/
}

.tags {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.tag {
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  line-height: normal;
  padding: 0.5rem 1rem;
  margin: 0;
  height: auto;
  border: none;
  vertical-align: middle;
  -webkit-appearance: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
}

.active {
  font-weight: bold;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4px;
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
}

.image-grid img:hover {
  transform: scale(1.1);
  transition: 0.6s all ease !important;
  cursor: pointer;
}

.image {
  width: 100%;
  display: block;
}

@media screen and (max-width: 960px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
    width: 95%;
  }
}

.image-container.light-theme {
  background-color: #fff;
  color: #000;
}

.tags.light-theme {
  color: #000;
}

.image-container.dark-theme {
  background-color: #282828;
  color: #fff;
}

.tags.light-theme button {
  color: #000;
}

.tag.dark-theme {
  color: #fff;
}

@media screen and (min-width: 320px) {
  .image-grid {
    grid-template-columns: repeat(1, 1fr);
    width: 95%;
    max-width: 1200px;
  }
}

@media screen and (min-width: 801px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
    width: 95%;
    max-width: 1200px;
  }
}

@media screen and (min-width: 1025px) {
  .image-grid {
    grid-template-columns: repeat(4, 1fr);
    width: 95%;
    max-width: 1800px;
  }
}

@media screen and (min-width: 1281px) {
  .image-grid {
    width: 95%;
    max-width: 1800px;
  }
}
