* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Titillium Web", sans-serif;
  text-decoration: none;
}


body::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #000;
}

::-webkit-scrollbar-track {
  background: #282828;
}

.slideresponsive {
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

.App.light-theme{
  background-color: #fff;
  color: #000;
}

.App.dark-theme{
  background-color: #282828;
  color: #fff;
}
