.theme-switch {
  background: none;
  position: absolute;
  top: 33px;
  right: 50px;
  border: none;
  cursor: pointer;
}

.theme-icon {
  height: 20px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}

@media screen and (max-width: 820px) {
  .theme-icon {
    height: 15px;
  }

  .theme-switch {
    top: 30px;
    right: 10px;
  }
}
