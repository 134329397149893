.footer-container {
  background-color: #282828;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  /* max-width: 1000px;
  width: 100%;*/
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50%;
  padding-bottom: 5%;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  color: #fff;
}

.website-rights {
  color: #fff;
}

@media screen and (max-width: 820px) {
  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

.footer-container.light-theme {
  background-color: #fff;
  color: #000;
}

.social-media.light-theme {
  color: #000;
}

.social-icons.light-theme a {
  color: #000;
}
