.NavbarItems {
  background: #282828;
  height: 150px;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;
}

.navbar-logo {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /*padding: 35px 0;*/
}

.fa-palette {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: center;
  /*padding: 0px 0px 0px 0px;*/
  margin-top: 13%;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  /*padding: 0.5rem 1rem;*/
}

.nav-links:hover {
  color: #e6e6e6;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu.active {
    background: #282828;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 20;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #e6e6e6;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 25px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}

.NavbarItems.light-theme {
  background-color: #fff;
}

.nav-menu.light-theme li a,
.nav-menu.light-theme li a:visited {
  color: #000;
}

.nav-menu.dark-theme li a,
.nav-menu.dark-theme li a:visited {
  color: #fff;
}
