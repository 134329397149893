h1 {
  font-size: 1rem;
  font-family: "Titillium Web";
  font-weight: 600;
  font-style: normal;
  line-height: 1.2em;
  letter-spacing: 0px;
  text-transform: none;
}

p {
  margin-top: 45px;
  text-align: justify;
}

.about-picture {
  float: left;
  padding: 0 2px 0 0;
}

.about-container.dark-theme {
  background: #282828;
}

.about-container.light-theme {
  background: #fff;
}

@media screen and (max-width: 960px) {
  .about-container.dark-theme {
    background: #282828;
    margin-top: 10vw;
  }

  .about-container.light-theme {
    background: #fff;
    margin-top: 10vw;
  }
}
